import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import UserProfile from '../components/UserProfile.vue';
import HashtagProfile from '../components/HashtagProfile.vue';
//import HashtagProfile from '../components/HashtagProfile.vue';

const routes = [
  {
    method: 'GET',
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    method: 'GET',
    path: '/user/:id',
    name: 'UserProfile',
    component: UserProfile,
    props: true,
  },
  {
    method: 'GET',
    path: '/hashtag/:category',
    name: 'HashtagProfile', // Ensure the name is correct
    component: HashtagProfile, // Add the component property
    props: route => ({ id: route.params.category }),
    //path: '/hashtag/:id',
    //name: 'HastagProfile',
    //component: HashtagProfile,
    //props: true,
  },
  {
    method: 'GET',
    path: '/user-profiles/:id',
    handler: 'user-profile.findUserWithDetails',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
