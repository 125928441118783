<template>
  <div v-if="isOpen" class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <slot></slot>
      <button class="close-button" @click="close">X</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: rgb(0, 0, 0);
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 4em;
  cursor: pointer;
  color: rgb(243, 12, 12); /* Change color to white */
  font-family: 'Arial', sans-serif; /* Change font */
}
</style>
