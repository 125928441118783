<template>
  <div id="app">
    <MainHeader />
    <nav>
      <router-link to="/">Home </router-link>
      <router-link to="/UserProfile"> UserProfile </router-link>
    </nav>
    <main class="main-content">
      <router-view></router-view> <!-- This is where your routed components will be displayed -->
    </main>
  </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue';

export default {
  name: 'App',
  components: {
    MainHeader
  }
};
</script>

<style>
/* Add global styles here */
body {
  background-color: rgb(0, 0, 0); /* Change the page background to black*/
  color: white; /* Optional: change text color to white for better visibility */
}

.main-content {
  padding: 20px;
  background-color: #000000;
}
</style>
