<template>
  <div class="Base-Div">
    <h1>FEEDS</h1>
    <div class="video-list">
      <div
        class="video-container"
        v-for="(video, index) in getUserInfo()"
        :key="index"
        :data-embed-url="video.embedURL"
        :href="`#${index}`"
      >
        <div class="user-info">
          <img :src="video.profilePicture" alt="User Picture" class="user-picture" />
          <a :href="`http://localhost:8080/user/${encodeURIComponent(video.profileName)}`" class="profile-name">{{ video.profileName }}</a>
        </div>
        <iframe
          :src="video.embedURL"
          frameborder="0"
          allowfullscreen
          class="responsive-video"
          ref="videoIframe"
          autoplay="true"
          mute="1"
          allow="autoplay; encrypted-media"
        ></iframe>
        <div class="interaction-buttons">
          <button class="thumbs-up" @click="toggleThumbsUp(index)">
            <i :class="{'fas fa-thumbs-up': true, active: isThumbsUpActive(index)}"></i>
          </button>
          <button class="thumbs-down" @click="toggleThumbsDown(index)">
            <i :class="{'fas fa-thumbs-down': true, active: isThumbsDownActive(index)}"></i>
          </button>
          <div>{{ thumbsUpCounts[index] - thumbsDownCounts[index] }}</div>
        </div>
        <div class="Hash-container">
          <div v-for="(hashtag, idx) in video.hashtags" :key="idx">
            <a :href="`http://localhost:8080/hashtag/${hashtag}`">{{ hashtag }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, nextTick } from 'vue';

const BASE_URL = 'http://www.rocken2k.com:1337';

export default {
  name: 'UserProfile',
  setup() {
    const userVideos = ref([]);
    const thumbsUpCounts = ref([]);
    const thumbsDownCounts = ref([]);



    onMounted(() => {
      console.log('MOUNTING');
      fetchUserData();
    });

    const fetchUserData = async () => {
      console.log('FECHING USER DATAaa');
      try {
	console.log('Before axios.get call');
        const response = await axios.get(`http://www.rocken2k.com:1337/api/user-video-embeds/?populate[embedURL]=*&populate[user_profile][populate]=*&populate[hashtags]=*`);
	console.log('After axios.get call');
        userVideos.value = response.data.data;

        // Log the entire response data to inspect it
        console.log('API Response Data:', response.data);

        // Log only the hashtags part of the response
        const hashtags = userVideos.value.map(item => getHashtags(item));
        console.log('Hashtags:', hashtags);


        userVideos.value.forEach(() => {
          thumbsUpCounts.value.push(0);
          thumbsDownCounts.value.push(0);
        });
        await nextTick();
        setupIntersectionObserver();
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const getUserInfo = () => {
      console.log('GETUSERINFO function');
      return userVideos.value.map((item) => {
        return {
          embedURL: getEmbedURL(item),
          profileName: getProfileName(item),
          profilePicture: getProfilePicture(item),
          userId: getUserId(item),
          hashtags: getHashtags(item),
        };
      });
    };

    const getEmbedURL = (item) => item.attributes?.embedURL;

    const getProfileName = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes || 'W/O userProfile associated';
      return userProfile.ProfileName;
    };

    const getProfilePicture = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes || 'W/O userProfile associated';
      return userProfile.ProfilePicture?.data?.attributes?.formats?.thumbnail?.url
        ? `${BASE_URL}${userProfile.ProfilePicture.data.attributes.formats.thumbnail.url}`
        : '';
    };

    const getUserId = (item) => item.attributes?.user_profile?.data?.id;

    const getHashtags = (item) => item.attributes?.hashtags?.data.map((hashtag) => hashtag.attributes.HashCategorie) || [];

    const toggleThumbsUp = (index) => {
      thumbsUpCounts.value[index] = thumbsUpCounts.value[index] === 0 ? 1 : 0;
      if (thumbsUpCounts.value[index] === 1) {
        thumbsDownCounts.value[index] = 0; // Ensure thumbs down is reset
      }
    };

    const isThumbsUpActive = (index) => {
      return thumbsUpCounts.value[index] === 1;
    };

    const toggleThumbsDown = (index) => {
      thumbsDownCounts.value[index] = thumbsDownCounts.value[index] === 0 ? 1 : 0;
      if (thumbsDownCounts.value[index] === 1) {
        thumbsUpCounts.value[index] = 0; // Ensure thumbs up is reset
      }
    };

    const isThumbsDownActive = (index) => {
      return thumbsDownCounts.value[index] === 1;
    };

    const setupIntersectionObserver = () => {
      const observers = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const iframe = entry.target;
          const embedURL = iframe.getAttribute('data-original-src');

          if (entry.intersectionRatio > 0) {
            iframe.src = `${embedURL}?autoplay=1&mute=1`;

            const video = document.createElement('video');
            video.src = embedURL;
            video.addEventListener('loadedmetadata', () => {
              console.log('Metadata loaded');
              const duration = video.duration;
              console.log(`Video duration: ${duration} seconds`);
            });
            console.log(`Iframe with embedURL: ${embedURL} is in the viewport`);
          } else {
            iframe.src = '';
            iframe.setAttribute('data-original-src', embedURL);
          }
        });
      });

      document.querySelectorAll('.responsive-video').forEach((iframe) => {
        const embedURL = iframe.getAttribute('src');
        iframe.setAttribute('data-original-src', embedURL);
        observers.observe(iframe);
      });
    };

    return {
      userVideos,
      getUserInfo,
      thumbsUpCounts,
      thumbsDownCounts, // Return thumbsDownCounts
      toggleThumbsUp,
      toggleThumbsDown, // Return toggleThumbsDown
      isThumbsUpActive,
      isThumbsDownActive,
    };
  },
};
</script>

<style>
.Base-Div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.video-list { /*Todos os videos */
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.video-container {  /*Video + User Profile + Buttons + tags */
  position: relative;
  width: 100%;
  max-width: 1100px;
  aspect-ratio: 16/9;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 280px;
  width: 100%;
}

.user-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-name {
  flex-grow: 1;
  font-family: Arial, sans-serif;
  color: rgb(112, 24, 24);
  font-size: 22px;
}

.responsive-video {
  width: 100%;
  height: auto;
  max-width: 800px;
}

.interaction-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  margin-left: 0;
  width: 100%;
  padding-left: 230px;
}

.thumbs-up,
.thumbs-down {
  font-size: 20px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.thumbs-up:active,
.thumbs-down:active {
  background-color: white;
  color: black;
}

.fas.fa-thumbs-up.active {
  color: yellow;
}

.fas.fa-thumbs-down.active {
  color: red; /* Change the color as desired for the active state */
}

.Hash-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

.Hash-container a {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
}
</style>
