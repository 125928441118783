<template>
    <header class="header">
      <div class="logo">
        <h1 class="site-name">Vulcano</h1>
      </div>
      <div class="search-login">
        <input class="search-box" type="text" placeholder="Search here your desire..." v-model="searchQuery">
        <button @click="search">Search</button>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    data() {
      return {
        searchQuery: ''
      };
    },
    methods: {
      search() {
        alert(`Searching for: ${this.searchQuery}`);
        // Implement search functionality here
      },
    }
  };
  </script>
  
  <style>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #000000;
    color: rgb(0, 0, 0);
  }

  .site-name {
  color: #57031b; /* Dark wine color */
  }
  
  .logo h1 {
    margin: 0;
  }
  
  .search-login {
    display: flex;
    align-items: center;
  }
  
  .search-login input {
    margin-right: 10px;
    padding: 5px;
  }
  
  .search-login button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #555;
    color: white;
    border: none;
    cursor: pointer;
  }

  .search-login .search-box {
  margin-right: 10px;
  padding: 5px;
  color: white; /* Change text color inside the search box */
  background-color: #333; /* Change background color of the search box */
  border: 1px solid #555; /* Optional: Add border color */
  }
  
  .search-login button:hover {
    background-color: #777;
  }

  html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  color: white; /* Optional: Change the text color to ensure readability */
  /*display: flex;*/
  justify-content: center; /* Center content horizontally, optional */
  align-items: center; /* Center content vertically, optional */
   }
  </style>
  